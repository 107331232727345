<template>
    <div class="transactionReport">
        <div class="main-flex">
            <edit-btns class="btn-box" 
                :bnts="['query','output','goBack']"
                @queryClick="loadData()"  
                @outputClick="exportExcel()" 
                @goBackClick="back()"/>
        </div>
        <div class="data-box">
            <div class="flex-box">
                <div class="filter-box">
                    <div class="bnt verticalCenter" :class="{selected:!queryType}" @click="queryType=false" >
                        <div class="title">
                            <span>今天 <br/>({{userInfo?.Rpt_Date}})</span>
                        </div>
                    </div>
                    <div class="bnt verticalCenter" :class="{selected:queryType}" @click="queryType=true" >
                        <div class="title">
                            <span>按日期范围</span>
                        </div>
                    </div>
                    <div class="inline-block">
                        <el-date-picker class="from-date"
                            v-model="businessHours"
                            type="daterange"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                            :default-time="defaultTime"
                            :disabled='queryType==false'
                        > </el-date-picker>
                    </div>
                    <div class="from-box">
                        <div class="lable-txt">应收账户:</div>
                        <select-tree class="from-input" v-model="corpTransIDs" placeholder="请选择账户" 
                            :data="treeCorpData" 
                            searchPlaceholder="编号名称模糊检索"
                            is-search
                            :filter-node-method="filterCorpInfo"
                            title="转入账户选择" />
                    </div>
                    <div class="from-box">
                        <div class="lable-txt">付款方式:</div>
                        <div class="from-input">
                            <el-select v-model="Pay_IDs" multiple collapse-tags>
                                <el-option :label="item.label" :value="item.value" v-for="item in payTypes" :key="item"></el-option>
                            </el-select>
                        </div>
                    </div>
                    <div class="from-box">
                        <div class="lable-txt">交易状态:</div>
                        <div class="from-input">
                            <el-select v-model="payStatus">
                                <el-option label="所有" :value="-1"></el-option>
                                <el-option :label="item" :value="index" v-for="(item,index) in payStatusList" :key="item"></el-option>
                            </el-select>
                        </div>
                    </div>
                    <div class="from-box">
                        <div class="lable-txt">交易号:</div>
                        <key-board-input class="from-input search"
                            type="text" v-model="searchTxt" placeholder="交易号模糊检索"
                            isKeyEnterClose isBlurClose
                            :keyOptions="{isKeyDownEnter:true}"
                        ></key-board-input>
                    </div>
                </div>
                <div class="table-box">
                    <el-table class="el-table--scrollable-y" style="width: 100%;height:100%" 
                        ref="tableEl"
                        border
                        v-mouse-scroll="{scrollName:'.el-table__body-wrapper'}"
                        show-summary
                        :summary-method="getSummaries"
                        :data="pageTableData"
                    >
                        <el-table-column prop="Rpt_Date" label="业务日期" width="85"  :formatter="shortDateFormat" align="left"></el-table-column>
                        <el-table-column prop="Corp_Code" label="账户编号" min-width="100" align="left"></el-table-column>
                        <el-table-column prop="Corp_Name" label="账户名称" min-width="100" align="left"></el-table-column>
                        <el-table-column prop="Pay_Name" label="付款方式" min-width="85"></el-table-column>
                        <el-table-column prop="Pay_Money" label="付款金额" min-width="80" align="right" data-format="number" :formatter="priceFormat"></el-table-column>
                        <el-table-column prop="Repuest_Time" label="交易时间" min-width="130" align="left" :formatter="dateFormat"></el-table-column>
                        <el-table-column prop="PlatformTxnNo" label="交易号" min-width="130"></el-table-column>
                        <el-table-column prop="Card_No" label="会员卡号" min-width="140" align="left"></el-table-column> 
                        <el-table-column prop="payment_Status" label="交易状态" min-width="100">
                            <template #default="scope">
                                {{payStatusList[scope.row.payment_Status]||""}}
                            </template>
                        </el-table-column> 
                        <el-table-column prop="" label="操作" min-width="60">
                            <template #default="scope">
                                <el-button v-if="scope.row.payment_Status==2" type="text" size="small" @click="select(scope.row)">查询</el-button>
                                <el-button v-if="scope.row.payment_Status==3 || scope.row.payment_Status==4" type="text" size="small" @click="revoke(scope.row)">撤销</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
                <table-page-btn class="fy" :data="filterData" 
                    :default-index="currentPage" 
                    @current-change="(index)=>currentPage=index"
                    @page-change="(list)=>pageTableData=list" 
                    :pageSizes="[20,30,50]"
                    :page-size="pageSize"
                    @sizeChange="(size)=>pageSize=size">
                </table-page-btn>
            </div>
        </div>
    </div>
</template>

<script>
import FileSaver from 'file-saver'
import XLSX from 'xlsx'
/** 流水报表 */
export default {
    name:"transactionReport",
    data(){
        let pageSize= this.$cacheData.get("transactionReport_pageSize")||20;
        return {
            userInfo:null,
            Corp_Name:"",
            Corp_AutoID:"",
            /**账户的最后更新时间  操作数据时用 */
            LastUpdated_Time:null,
            /**付款方式数据 */
            payTypes:[{label:"聚合支付",value:"801010301"},{label:"正品CRM支付",value:"801010401"}],
            Pay_IDs:[],
            /**账户数据 */
            ARCorpInfoList:[],
            //转入账户
            corpTransIDs:[],
            /**默认日期范围 */
            defaultTime:[new Date(),new Date()],
            /**选中日期范围 */
            businessHours:[new Date(),new Date()],
            /**true: 日期范围    false：当前营业数据*/
            queryType:false,
            currentPage:1,
            pageSize:pageSize,
            /**交易状态数据 */
            payStatusList:['失败', '成功', '处理中', '支付成功，来款清账失败', '支付成功，预充值失败', '已撤销'],
            /** 交易状态 */
            payStatus:-1,
            /**检索 */
            searchTxt:"",
            /**账单信息 */
            tableData:[],
            pageTableData:[],
        }
    },
    computed:{
        /**选择 账户下拉数据*/
        treeCorpData(){
            let tree=[];
            let category={}
            this.ARCorpInfoList?.forEach(it=>{
                if(!category[it.Corp_KindID]){
                    category[it.Corp_KindID]={
                        id:"kind"+it.Corp_KindID,
                        name:it.Corp_KindName,
                        children:[]
                    }
                    tree.push(category[it.Corp_KindID]);
                }
                category[it.Corp_KindID].children.push(Object.assign({
                    id:it.Corp_AutoID,
                    name:it.Corp_Code+"-"+it.Corp_Name,
                },it));
            })
            return tree;
        },
        /**选择 付款方式下拉数据*/
        treePayTypes(){
            let tree=[];
            this.payTypes?.forEach(it=>{
                let children=[];
                it.PayTypes.forEach(it=>{//1现金支付 2 普通支付 801010301 聚合支付  801010401 正品CRM3.0会员支付
                    if(it.INTERFACE_TYPE=="1" || it.INTERFACE_TYPE=="2" || it.INTERFACE_TYPE=="801010301" || it.INTERFACE_TYPE=="801010401"){
                        children.push(Object.assign({
                            id:it.PAY_ID,
                            name:it.PAY_CODE+"-"+it.PAY_NAME,
                        },it))
                    } 
                })
                if(children.length>0){
                    tree.push({
                        id:"kind"+it.PAYKIND_ID,
                        name:it.PAYKIND_CODE+"-"+it.PAYKIND_NAME,
                        children:children
                    });
                }
                
            })
            return tree;
        },
        /**筛选 */
        filterData(){
            let data=this.tableData||[];
            if(this.corpTransIDs?.length>0){//应收账户
                data=data.filter(it=> this.corpTransIDs.indexOf(it.Corp_AutoID)>=0);
            }
            if(this.Pay_IDs?.length>0){//付款款方式
                data=data.filter(it=> this.Pay_IDs.some(pay=>pay==it.PaymentType));
            }
            if(this.payStatus>=0){//交易状态
                data=data.filter(it=>it.payment_Status==this.payStatus);
            }
            if(this.searchTxt?.trim()){//交易号
                let text=this.searchTxt.trim().toUpperCase();
                data=data.filter(it=>it.PlatformTxnNo?.toUpperCase()?.indexOf(text)>=0);
            }
            return data;
        },
        /**合计数据 */
        summaryTableList(){
            let total={Pay_Money:0}
            this.filterData?.forEach((it)=>{
                total.Pay_Money+=it.Pay_Money;
            });
            return total;
        },
    },
    watch:{
        pageSize(newVal){
            this.$cacheData.set("corpARMoney_pageSize",newVal);
        },
        tablePageSum(newVal){
            if(newVal<=this.tablePageIndex){
                this.tablePageIndex=0;
            }
        },
        searchTxt(){
            this.tablePageIndex=0;
        }
    },
    mounted(){
        this.userInfo = this.$auth.getUserInfo();
        let Rpt_Date=new Date(this.userInfo.Rpt_Date);
        let endDate=Rpt_Date.Format("yyyy-MM-dd");
        Rpt_Date.setDate(Rpt_Date.getDate()-30);
        let startDate=Rpt_Date.Format("yyyy-MM-dd");
        this.businessHours=[startDate,endDate];

        this.Corp_Name=this.$route.params.Corp_Name;
        this.Corp_AutoID=this.$route.params.id;
        this.LastUpdated_Time=this.$route.params.LastUpdated_Time;
        const loading = this.$loading({
            text: "数据加载中...",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.7)",
        });
        Promise.all([this.loadCorpInfo(false),this.loadData(false)]).then(()=>{
            loading.close();
        }).catch(err=>{
            console.log(err) // 失败 
        })
    },
    methods:{
        back(){
            this.$router.push({
                name: 'corpARMoney',
            }); 
        },
        change(){
            if(this.queryType==false){
                this.queryType=true
            }
        },
        /**搜索 */
        filterNode(value, data){
            if (!value) return true;
            value=value.toLocaleUpperCase();
            return data.PAY_CODE?.toLocaleUpperCase().includes(value) || data.PAY_NAME?.toLocaleUpperCase().includes(value);
        },
        /**加载账户 */
        loadCorpInfo(isLoaing){
            let loading;
            if(isLoaing!==false){
                loading = this.$loading({
                    text: "数据加载中...",
                    spinner: "el-icon-loading",
                    background: "rgba(0, 0, 0, 0.7)",
                });
            }
            return this.$httpAES.post("Bestech.CloudPos.GetBestARCorpInfo", {}).then((d)=>{
                if(isLoaing!==false){
                    loading.close();
                }
                if(d.ResponseHeader.ResultCode!=0){
                    this.$message.error(d.ResponseHeader.ResultDesc)
                    return;
                }else{
                    this.ARCorpInfoList=d.ResponseBody||[];
                }
            }).catch((e)=>{
               if(isLoaing!==false){
                    loading.close();
                }
                this.$alert("账户数据加载失败"+e.message, "提示", {confirmButtonText: "确定"});
            });
        },
        /**搜索 */
        filterCorpInfo(value, data){
            if (!value) return true;
            value=value.toLocaleUpperCase();
            return data.Corp_Name?.toLocaleUpperCase().includes(value) || data.Corp_Code?.toLocaleUpperCase().includes(value);
        },
        /**查询数据 */
        loadData(isLoaing){
            let loading;
            if(isLoaing!==false){
                loading = this.$loading({
                    text: "数据加载中...",
                    spinner: "el-icon-loading",
                    background: "rgba(0, 0, 0, 0.7)",
                });
            }
            let time = JSON.parse(JSON.stringify(this.businessHours));
            let userInfo=this.$auth.getUserInfo(); //获取用户id
            let param={
                User_ID:userInfo?.User_ID,
                Operator_Name:userInfo?.Login_Name,
                DateRange_YN:this.queryType,//是否日期范围查询  true: 日期范围    false：当前营业数据
                Begin_Date:new Date(time[0]).Format("yyyy-MM-dd"),//开始日期
                End_Date:new Date(time[1]).Format("yyyy-MM-dd"), //结束日期
            }
            return this.$httpAES.post("Bestech.CloudPos.GetArInterfacePaymentSerials", param).then((d)=>{
                if(isLoaing!==false){
                    loading.close();
                }
                if(d.ResponseHeader.ResultCode!=0){
                    this.$message.error(d.ResponseHeader.ResultDesc)
                    return;
                }else{
                    this.tableData=d.ResponseBody;
                    console.log(d)
                }
            }).catch((e)=>{
                if(isLoaing!==false){
                    loading.close();
                }
                this.$alert("数据加载失败"+e.message, "提示", {confirmButtonText: "确定"});
            });
        },
        /*时间格式 */
        dateFormat(row, column, cellValue) {
            if(!cellValue || cellValue=='0001-01-01T00:00:00'){
                return '';
            }
            return (new Date(cellValue)).Format('yyyy-MM-dd hh:mm:ss');
        },
        /*时间格式 */
        shortDateFormat(row, column, cellValue){
            if(!cellValue || cellValue=='0001-01-01T00:00:00'){
                return '';
            }
            return (new Date(cellValue)).Format('yyyy-MM-dd');
        },
        /*单价格式 截取两位小数 */
        priceFormat(row, column, cellValue){
            if(isNaN(cellValue)){
                return ""
            }else{
                return parseFloat(parseFloat(cellValue).toFixed(2));
            }
        },
        /**数据分页 */
        billPage(type){
            if(type==1){
                if(this.tablePageIndex+1<this.tablePageSum){
                    this.tablePageIndex++;
                }else{//下一页
                    this.$message.warning('已到最后一页');
                }
            }else{//上一页
                if(this.tablePageIndex==0){
                    this.$message.warning('已到第一页');
                }
                this.tablePageIndex>0?this.tablePageIndex--:this.tablePageIndex=0;
            }
        },
        /**导出excel */
        exportExcel(){
            if(!this.$cacheData?.globalVariable?.GlobalSysSetting.AllowUserPrintReports){
                this.$message.warning('您没有导出报表权限，请先分配权限！');
                return;
            }
            /**列名 */
            let headers = ["业务日期","账户编号","账户名称",'付款方式','付款金额','交易时间',"交易号","会员卡号","交易状态"];
            let data=[];
            data.push(headers.map((it)=>{return {value:it}}));
            // 将数据转化成 excel 所需的数据格式
            //[{value:"显示内容",col:"站用列数 默认1",row:"站用行数 默认1",t:"b布尔值，n数字，e错误，s字符串，d日期，z存根 默认s"}]
            this.filterData?.forEach(it=>{
                data.push([
                    {value:this.shortDateFormat(null,null,it.Rpt_Date)},
                    {value:it.Corp_Code},
                    {value:it.Corp_Name},
                    {value:it.Pay_Name},
                    {value:this.priceFormat(null,null,it.Pay_Money),t:'n'},
                    {value:this.dateFormat(null,null,it.Repuest_Time)},
                    {value:it.PlatformTxnNo},
                    {value:it.Card_No},
                    {value:this.payStatusList[it.payment_Status]||""},
                ])
            })
            let total=["合计","","","",parseFloat(this.summaryTableList.Pay_Money.toFixed(2)),"","","",""];
            data.push(total.map((it)=>{return {value:it}}));
            try {
                let ws = this.$excelCommon.addSheetCell(data);
                let wb = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(wb, ws, '第三方交易流水表');
                let wbout = XLSX.write(wb, {bookType: 'xlsx', bookSST: false, type: 'array'});
                FileSaver.saveAs(new Blob([wbout], {type: 'application/octet-stream'}), '第三方交易流水表.xlsx')
            } catch (e) {
                if (typeof console !== 'undefined') console.log(e)
            }
        },
        //合计
        getSummaries({ columns, data }){
            const sums= [];
            let total=this.summaryTableList;
            columns.forEach((column, index) => {
                if(total[column.property]!=undefined){
                    sums[index]= parseFloat(total[column.property].toFixed(2));
                }else{
                    sums[index]="";
                }
            })
            sums[0]="合计";
            return sums;
        },
        /**查询支付结果 */
        select(data){
            let userInfo=this.$auth.getUserInfo(); //获取用户id
            const loading = this.$loading({
                text: "查询支付结果中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            let param={
                User_ID:userInfo?.User_ID,  //用户id
                Operator_Name:userInfo?.Login_Name, //操作员
                Operate_EquipmentID:userInfo?.Site_Serial, //设备id
                Pos_StationName:userInfo?.Site_Name, //站点名称
                Biz_ID:data.Biz_ID, //流水号ID
            }
            this.$httpAES.post("Bestech.CloudPos.PayARCorpRequeryResult", param).then((d)=>{
                loading.close()
                if(d.ResponseHeader.ResultCode!=0){
                    this.$message.error(d.ResponseHeader.ResultDesc);
                }else{
                    if(d.ResponseBody?.PrintsInfo){//打印
                        setTimeout(()=>{
                            this.$webBrowser.posPrint(d.ResponseBody.PrintsInfo);
                        },100);
                    }
                    this.$message.success("操作成功");
                    this.loadData();
                }
            }).catch((e)=>{
                loading.close()
                this.$alert(e.message, "提示", {confirmButtonText: "确定"});
            });
        },
        /** 撤销 */
        revoke(data){
            const loading = this.$loading({
                text: "数据保存中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            let userInfo= this.$auth.getUserInfo();
            this.$httpAES.post("Bestech.CloudPos.RefundARPaymentSerial",{
                User_ID:userInfo?.User_ID,  //用户id
                Operator_Name:userInfo?.Login_Name, //操作员
                Operate_EquipmentID:userInfo?.Site_Serial, //设备id
                Pos_StationName:userInfo?.Site_Name, //站点名称
                Biz_ID:data.Biz_ID, //流水号ID
            }).then((d)=>{
                loading.close();
                if(d.ResponseHeader.ResultCode==0){
                    this.$message.success("撤销成功");
                    data.payment_Status=5;
                }else{
                    this.$message.error(d.ResponseHeader.ResultDesc);
                }
            }).catch((e)=>{
                loading.close();
                this.$message.error('撤销失败：'+e.message);
                console.log('撤销失败：',e);
            })
        },
    }
}
</script>

<style lang="scss">
    @import './transactionReport.scss'
</style>